.footer {
  /* height: 335px; */
  background-color: #478BA3;
  padding-left: 68px;
  padding-top: 28px;
  /* position: absolute;
  bottom: 0; */
  position: relative;
}

.footer .left {
}
.w-20{
  width: 20%;
}
.w-15{
  width: 15%;
}
.logo-text {
  text-align: left;
  position: absolute;
  top: 0;
}
.logo-text h2 {
  font-family: Inter;
  font-size: 18px;
  font-weight: 800;
  line-height: 27px;
  letter-spacing: 0.1em;

  color: #ffffff;
}

.logo-text span {
  font-family: Inter;
  font-size: 10px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.15em;

  color: #ffffff;
  position: relative;
  bottom: 20px;
}

.about {
  text-align: left;
}
.about h1 {
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0.1em;

  color: white;
}

.about .about-text {
  font-family: 'poppins';
  font-size: 0.85rem;
  text-align: left;
  color: white;
}


.footer-middle{

}
.footer-middle .heading{
  font-family: Inter;
font-size: 18px;
font-weight: 600;
line-height: 27px;
letter-spacing: 0.1em;
color: white;
text-align: left;

}

.footer-middle .list{
  display: flex;
  flex-direction: column;
  text-align: left;
}
.footer-right .list li,
.footer-middle .list li{
  font-family: "poppins" ;
font-size: 0.85rem;
text-align: left;
color: white;
}


.footer-right{
  display: flex;
  flex-direction: column;
  text-align: left;
}
.footer-right span{
  font-family: Inria Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0.08em;
  text-align: left;
  color: white;
  position: relative;
  top: 43px;
}

.footer .social-links{
position: absolute;
bottom: 0;
left: 71px;
display: flex;
gap: 5px;
 
}
.footer .social-links img{
  width: 30px;
}

.rights{
  background-color: #167699;
  border-top: 1px solid gray;
  color: white;
}
.footer-heading{
font-family: 'poppins-bold' !important;
color: #9F303C !important;
}